function getPhoneNumberHTML(route) {
  const phoneNumber = window.config.contact_phone_number;
  const phonePrint = window.config.contact_phone_print;

  return `<span class="font-bold"><a href="tel:${phoneNumber}" data-testid="callback-phone-number">${phonePrint}</a></span>`;
}

/**
 * Retrieve the bodies of text to use on the Callback.vue component
 *
 * @returns {{header: string, subHeader: string}}
 */
export function useCallbackDetails() {
  /** @type {string} The current page route */
  const currentRoute = (() => route().current())();

  const telephoneHTML = getPhoneNumberHTML(currentRoute);

  /** @type {{header: string, subHeader: string}} The default header & subHeader values */
  const defaultValues = {
    header: `Call us now on ${telephoneHTML} to learn more about our services`,
    subHeader: 'Lines are open Monday to Friday 9am - 5.30pm',
  };

  /**
   * Check the base route of the currentRoute
   *
   * @param {string} route
   * @returns {boolean}
   */
  const baseRoute = (route) => currentRoute.startsWith(route);

  switch (true) {
    case baseRoute('estate-planning'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about our Estate Planning service`,
        subHeader: defaultValues.subHeader,
        product: 'estatePlanning',
      };
    case baseRoute('will-writing'):
      return {
        header: `Call us now on ${telephoneHTML} to speak with one of our experts about your will`,
        subHeader: defaultValues.subHeader,
        product: 'willWriting',
      };
    case baseRoute('life-insurance'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about our Life Insurance `,
        subHeader: defaultValues.subHeader,
        product: 'lifeInsurance',
      };
    case baseRoute('lasting-power-of-attorney'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about our LPA service or request a call back`,
        subHeader: defaultValues.subHeader,
        product: 'lpa',
      };
    case baseRoute('online-will'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about our will writing service`,
        subHeader: defaultValues.subHeader,
        product: 'will',
      };
    case baseRoute('free-will'):
    case baseRoute('will.charity-will'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about our free will service`,
        subHeader: defaultValues.subHeader,
        product: 'freeWill',
      };
    case baseRoute('charities'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about partnering with us`,
        subHeader: defaultValues.subHeader,
        product: 'charity',
      };
    case baseRoute('bow'):
    case baseRoute('bow.onboarding2.promoted'):
      return {
        header: `Call us now on ${telephoneHTML} to learn about your score`,
        subHeader: defaultValues.subHeader,
        product: 'bow',
      };
    case baseRoute('funeral-directors'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about our Funeral Director partnership`,
        subHeader: defaultValues.subHeader,
        product: 'funeralDirectors',
      };
    case baseRoute('bereavement-support'):
      return {
        header: `Call us now on ${telephoneHTML} to learn more about our death concierge service`,
        subHeader: defaultValues.subHeader,
        product: 'deathConcierge',
      };
    case baseRoute('will'):
      return {
        header: `Need help creating your will? Call us on ${telephoneHTML}`,
        subHeader: 'Lines are open Monday to Friday 9am - 5.30pm',
        product: 'willDashboard',
      };
    default:
      return defaultValues;
  }
}
