<template>
  <section class="callback-section">
    <div :class="`callback-section__content-${darkMode ? 'dark' : 'light'}`">
      <h6 v-html="header" />
      <p data-testid="callback-opening-hours" v-html="subHeader" />
      <div
        v-if="showButtons"
        class="flex flex-col mt-10 lg:mt-14 md:flex-row md:flex-wrap gap-3"
      >
        <OLButton
          :variant="darkMode ? 'secondary-white' : 'secondary'"
          :link-to="scheduleCallBackLink"
        >
          Schedule a call
          <Arrow />
        </OLButton>
        <OLButton
          :variant="darkMode ? 'secondary-white' : 'secondary'"
          :link-to="requestCallBackLink"
        >
          Request a call back
          <Arrow />
        </OLButton>
      </div>
    </div>
  </section>
</template>

<script setup>
import OLButton from '@components/Button/OLButton.vue';
import Arrow from '@components/Icon/Arrow.vue';
import { useCallbackDetails } from '../composables/useCallbackDetails';

const props = defineProps({
  darkMode: {
    type: Boolean,
    default: false,
  },
  showButtons: {
    type: Boolean,
    default: true,
  },
});

const { header, subHeader, product } = useCallbackDetails();

const scheduleCallBackLink = route('leads.schedule-callback', { product });

const requestCallBackLink = route('leads.request-callback', { product });
</script>

<style lang="scss" scoped>
section.callback-section {
  @apply bg-[unset]
  w-full lg:w-9/10 xl:w-5/6 2xl:w-8/10
  max-w-content;

  div.callback-section__content-light,
  div.callback-section__content-dark {
    @apply col-span-8 lg:col-span-12
    px-5 xs:px-8 md:px-10
    py-14 sm:py-10 md:py-12
    lg:p-16
    rounded-3xl
    overflow-hidden;

    > h6 {
      @apply text-xl sm:text-2xl xl:text-3xl
      mb-4 xl:mb-6;
    }

    > p {
      @apply text-base lg:text-lg xl:text-xl;
    }
  }

  div.callback-section__content-dark {
    @apply bg-ol-dark-ink-200 text-white;
  }

  div.callback-section__content-light {
    @apply bg-ol-secondary-20;
  }
}
</style>
