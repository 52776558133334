<template>
  <ul class="mt-5">
    <li
      v-for="requirement in requirements"
      :id="requirement.id"
      class="text-sm flex gap-1"
      :class="
        requirement.met ? 'text-ol-status-success' : 'text-ol-status-error'
      "
    >
      <GreenCheck v-show="requirement.met" />
      <RedCross v-show="!requirement.met" />
      {{ requirement.name }}
    </li>
  </ul>
</template>
<script>
import GreenCheck from '@components/Icon/GreenCheck.vue';
import RedCross from '@components/Icon/RedCross.vue';

export default {
  components: { RedCross, GreenCheck },
  props: {
    requirements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
