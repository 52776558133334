<template>
  <div>
    <div
      ref="trustbox"
      class="trustpilot-widget"
      data-testid="trust-pilot"
      data-locale="en-GB"
      :data-template-id="templateId"
      data-businessunit-id="657080ebfa8c7e8d395f33bd"
      :data-style-height="size.height"
      :data-style-width="size.width"
      :data-theme="props.theme"
      data-font-family="Montserrat"
      :data-text-color="textColour"
    />
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';

const trustbox = ref(null);

watch(
  () => trustbox.value,
  () => {
    if (trustbox.value && window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox.value);
    }
  }
);

const props = defineProps({
  theme: {
    type: String,
    default: 'dark',
    validator(value) {
      return ['light', 'dark'].includes(value);
    },
  },
  stacked: {
    type: Boolean,
    default: false,
  },
});

const templateId = computed(() => {
  return props.stacked
    ? '5613c9cde69ddc09340c6beb'
    : '5419b732fbfb950b10de65e5';
});

const size = computed(() => {
  if (!props.stacked) {
    return { height: '24px', width: '280px' };
  } else return { height: '100%', width: '150px' };
});

const textColour = computed(() => {
  if (props.theme === 'dark') {
    return '#ffffff';
  }
  return '#000000';
});
</script>
